import React, { useRef, useState } from 'react';
import { useRequest, useCountDown } from 'ahooks';
import { Button, Form, Input, message } from 'antd';

import Header from 'components/Header/Header';
import { useTranslation } from 'react-i18next';

import { isEmail } from 'utils/pattern';
import UserService from 'apis/user';
import { useNavigate, Link } from 'react-router-dom';
import styles from '../Register/Register.module.scss';
import logoImg from 'assets/imgs/logo.png';
import bgImg from 'assets/imgs/bg.png';

const CountDownTime = 60000;

function ResetPassword() {
  return (
    <main className={styles.main} style={{ backgroundImage: `url(${bgImg})` }}>
      <div style={{ width: '100%' }}>
        <Header />
      </div>
      <ResetForm></ResetForm>
      <div style={{ height: 20 }}></div>
    </main>
  );
}

function UserReset() {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        background: '#fff',
        padding: '50px 0',
      }}>
      <div style={{ maxWidth: 500 }}>
        <ResetForm footer={false} header={false}></ResetForm>
      </div>
    </div>
  );
}

function ResetForm({ footer = true, header = true }) {
  const { t } = useTranslation();
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [targetDate, setTargetDate] = useState();
  const [countdown] = useCountDown({
    targetDate,
    onEnd: () => {},
  });

  const { run: runSendCode, loading: runSendCoding } = useRequest(
    UserService.sendCode,
    {
      manual: true,
      onSuccess(data) {
        if (data) {
          message.success(t('Res.SendSuccess'));
          setTargetDate(Date.now() + CountDownTime);
        }
      },
      onError(e) {},
    },
  );

  const { run: runRegister, loading: runRegistering } = useRequest(
    UserService.resetPassword,
    {
      manual: true,
      onSuccess(data) {
        if (data) {
          message.success(t('Res.PasswordResetSuccess'));
          navigate('/');
        }
      },
      onError(e) {},
    },
  );

  const onFinish = (values) => {
    console.log('Success:', values);
    const { password, rpassword } = values;
    if (password !== rpassword) {
      return message.error(t('Confirmed passwords do not match'));
    }
    runRegister(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const sendCode = () => {
    console.log();
    const formData = formRef.current.getFieldsValue();
    if (!isEmail(formData.email)) {
      message.error(t('invalid email'));
    }
    return runSendCode(formData.email);
  };
  return (
    <div className={styles['register-box']}>
      {header && (
        <div className={styles['logo-box']}>
          <img src={logoImg} alt='' />
        </div>
      )}
      {header && (
        <div className={styles['title-box']}>
          <h2>{t('DPN')}</h2>
          <h3>{t('Reset Password')}</h3>
        </div>
      )}
      <Form
        ref={formRef}
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'>
        <Form.Item
          label={t('Email')}
          name='email'
          rules={[{ required: true, message: t('Please input your email!') }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={t('Verification Code')}
          name='code'
          rules={[
            {
              required: true,
              message: t('Please input your verification code!'),
            },
          ]}>
          <div className={styles['input-btn-row']}>
            <Input />
            <Button
              loading={runSendCoding}
              disabled={countdown}
              onClick={sendCode}
              type='primary'>
              {countdown ? parseInt(countdown / 1000) : t('Send')}
            </Button>
          </div>
        </Form.Item>

        <Form.Item
          label={t('New Password')}
          name='password'
          rules={[
            {
              required: true,
              message: t('register|Please input your password!'),
              pattern: /(?=.*[0-9])(?=.*[a-zA-Z]).{8,30}/,
            },
          ]}>
          <Input.Password />
        </Form.Item>

        <Form.Item
          label={t('Repeat Password')}
          name='rpassword'
          rules={[
            { required: true, message: t('Please repeat your password!') },
          ]}>
          <Input.Password />
        </Form.Item>

        <div className={styles['submit-btn-box']}>
          <Button
            loading={runRegistering}
            size='large'
            type='primary'
            block
            htmlType='submit'>
            {t('Reset Password')}
          </Button>
        </div>
      </Form>
      {footer && (
        <div className={styles['footer-box']}>
          <Link to='/login'>{t('Login')}</Link>
          <Link to='/reset'>{t('Forget password')}?</Link>
        </div>
      )}
    </div>
  );
}

export default ResetPassword;
export { UserReset };
