import React from 'react';

import styles from 'containers/Register/Register.module.scss';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { Button, Form, Input, message } from 'antd';

import Header from 'components/Header/Header';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'ahooks';
import { useNavigate, Link } from 'react-router-dom';
import userApi from '../../apis/user';
import logoImg from 'assets/imgs/logo.png';
import bgImg from 'assets/imgs/bg.png';
import googleIcon from 'assets/imgs/google.png';

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { run: runSubmit, loading: runSubmiting } = useRequest(userApi.login, {
    manual: true,
    onSuccess(data) {
      message.success(t('Res.Welcome') + data.tokenData.email);
      navigate('/user/home');
    },
    onError(e) {},
  });

  const onFinish = (values) => {
    runSubmit(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <main className={styles.main} style={{ backgroundImage: `url(${bgImg})` }}>
      <div style={{ width: '100%' }}>
        <Header />
      </div>

      <div className={styles['login-box']}>
        <div className={styles['logo-box']}>
          <Link to='/'>
            <img src={logoImg} alt='' />
          </Link>
        </div>
        <div className={styles['title-box']}>
          <h2>{t('DPN')}</h2>
        </div>
        <Form
          name='basic'
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'>
          <Form.Item
            name='username'
            rules={[
              { required: true, message: t('Please input your username!') },
            ]}>
            <Input
              placeholder='Email'
              prefix={<UserOutlined style={{ marginRight: 10 }} />}
              size='large'
            />
          </Form.Item>
          <div style={{ height: 20 }}></div>
          <Form.Item
            label={t('Password')}
            name='password'
            rules={[
              { required: true, message: t('Please input your password!') },
            ]}>
            <Input.Password
              size='large'
              placeholder='Password'
              prefix={<LockOutlined style={{ marginRight: 10 }} />}
            />
          </Form.Item>
          <div className={styles['footer-box']}>
            <Link to='/register'>{t('Register')}</Link>
            <Link to='/reset'>{t('Forget password')}?</Link>
          </div>

          <div className={styles['submit-btn-box']}>
            <Button
              loading={runSubmiting}
              type='primary'
              block
              size='large'
              htmlType='submit'>
              {t('Login')}
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '10px 0',
            }}>
            <Button
              onClick={() => {
                window.location.href = '/api/google/auth';
              }}
              type='text'
              icon={
                <img style={{ width: '100%' }} src={googleIcon} alt='' />
              }></Button>
          </div>
        </Form>
      </div>
      <div style={{ height: 10 }}></div>
    </main>
  );
}

export default Login;
