import { Card } from 'antd';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styles from './UserHome.module.scss';

function UserHome() {
  const { tokenData } = useAuth();
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles['user-box']}>
        <Card
          title={t('Expiration Date')}
          bordered={false}
          style={{ width: '100%', maxWidth: '360px' }}>
          <div className={styles['card-content']}>
            <b>{moment(tokenData.subscriptionEndTime).format('YYYY-MM-DD')}</b>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default UserHome;
