import {
  ExportOutlined,
  HomeOutlined,
  HistoryOutlined,
  UnlockOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import { Outlet, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { Layout, Menu, Button, Drawer } from 'antd';
import React, { useState } from 'react';
import logoImg from 'assets/imgs/logo-white.png';
import logoImgSm from 'assets/imgs/logo-white-sm.png';
import languageIcon from 'assets/imgs/language-white.png';
import languageIconDark from 'assets/imgs/language.png';

import { useTranslation } from 'react-i18next';

import useAuth from 'hooks/useAuth';
import { useResponsive } from 'ahooks';
import styles from './User.module.scss';

const { Header, Sider, Content } = Layout;

function User() {
  const [collapsed, setCollapsed] = useState(false);
  const { token, tokenData } = useAuth();
  const responsive = useResponsive();

  const location = useLocation();
  const navigate = useNavigate();

  function handleClick(e) {
    navigate(e.key);
    if (!responsive.md) {
      setCollapsed(!collapsed);
    }
  }

  const { t, i18n } = useTranslation();
  const changeLocale = () => {
    const currentLocale = i18n.language;
    if (currentLocale == 'en') {
      i18n.changeLanguage('zh');
      window.localStorage.setItem('locale', 'zh');
    } else {
      i18n.changeLanguage('en');
      window.localStorage.setItem('locale', 'en');
    }
  };

  if (!token) {
    return <Navigate to='/' />;
  }

  const logout = () => {
    window.localStorage.clear();
    window.location.href = '/';
  };

  const menuList = [
    {
      key: '/user/home',
      icon: <HomeOutlined />,
      label: t('Home'),
    },
  ];

  return (
    <Layout className={styles.AdminContainer}>
      <Header
        className={styles['site-layout-background']}
        style={{ padding: 0 }}>
        <div className={styles['header-container']}>
          <div className={styles.logo}>
            <img src={logoImg} alt='' />
          </div>
          <div className={styles['right-box']}>
            {!responsive.md && (
              <div>
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: styles['trigger'],
                    onClick: () => setCollapsed(!collapsed),
                  },
                )}
              </div>
            )}
            {responsive.md && (
              <React.Fragment>
                <div>{tokenData.email}</div>
                <div>
                  <Button
                    onClick={changeLocale}
                    type='text'
                    icon={
                      <img
                        style={{ width: 18, position: 'relative', top: -3 }}
                        src={languageIcon}
                        alt=''
                      />
                    }></Button>
                </div>
                <div>
                  <Button
                    onClick={logout}
                    type='text'
                    icon={
                      <ExportOutlined style={{ color: '#fff' }} />
                    }></Button>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </Header>

      <Layout className={styles['site-layout']}>
        {responsive.md ? (
          <Sider trigger={null} theme='light' collapsible collapsed={collapsed}>
            <Menu
              mode='inline'
              selectedKeys={[location.pathname]}
              onClick={(e) => handleClick(e)}
              items={menuList}
            />
          </Sider>
        ) : (
          <Drawer
            closable={false}
            width={180}
            className={styles['drawer-container']}
            placement='right'
            onClose={() => setCollapsed(!collapsed)}
            open={collapsed}>
            <div className={styles['mobile-side']}>
              <div className={styles['mobile-side-user-box']}>
                <div style={{ color: '#333' }}>{tokenData.email}</div>
                <div>
                  <Button
                    onClick={changeLocale}
                    type='text'
                    icon={
                      <img
                        style={{ width: 18, position: 'relative', top: -3 }}
                        src={languageIconDark}
                        alt=''
                      />
                    }></Button>
                  <Button
                    onClick={logout}
                    type='text'
                    icon={<ExportOutlined />}></Button>
                </div>
              </div>
              <Menu
                mode='inline'
                selectedKeys={[location.pathname]}
                onClick={(e) => handleClick(e)}
                items={menuList}
              />
            </div>
          </Drawer>
        )}
        <Content className={styles.mainContainer}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default User;
