import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Download from 'containers/Download/Download';
import ResetPassword from './containers/ResetPassword/ResetPassword';
import { UserReset } from './containers/ResetPassword/ResetPassword';
import Register from './containers/Register/Register';
import Login from './containers/Login/Login';
import User from './containers/User/User';
import UserHome from './containers/UserHome/UserHome';
import GoogleLogin from 'containers/Google/GoogleLogin';
import GoogleLoginApp from 'containers/Google/GoogleLoginApp';
import Turnstile from 'containers/Turnstile/Turnstile';
import Pricing from 'containers/Pricing/Pricing';
export default function Router() {
  return (
    <Routes>
      <Route path='/' element={<Download />} />
      <Route path='/login' element={<Login />} />
      <Route path='/register' element={<Register />} />
      <Route path='/download' element={<Download />} />
      <Route path='/reset' element={<ResetPassword />} />
      <Route path='/oauth/login' element={<GoogleLogin />} />
      <Route path='/app/oauth/login' element={<GoogleLoginApp />} />
      <Route path='/turnstile' element={<Turnstile />} />
      <Route path='/pricing' element={<Pricing />} />
      <Route path='/user' element={<User />}>
        <Route path='home' element={<UserHome />} />
        <Route path='resetpassword' element={<UserReset />} />
      </Route>
    </Routes>
  );
}
